import React, {
  useState,
  useContext,
  useEffect
} from 'react';

import BaseModalNew from 'components/BaseModalNew';
import { PrimaryButton } from 'components/BaseButton';
import { Checkbox } from 'components/BaseInput';
import { useTranslation } from 'react-i18next';
import GlobalContext from 'layouts/Main/GlobalContext';
import cookies from 'js-cookie';

const AnnouncementModal = () => {
  const { systemSetting, locale } = useContext(GlobalContext);
  const { t } = useTranslation();

  const [isShowModal, setIsShowModal] = useState(false);
  const [neverShowAgain, setNeverShowAgain] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const alertParam = systemSetting.find(item => item.key === 'alertMessage');
    
    if (alertParam) {
      setAlertMessage(alertParam.value === '' ? '' : JSON.parse(alertParam.value).find(item => item.lang === locale));
    }

  }, [systemSetting]);

  useEffect(() => {
    if (alertMessage !== '' && (cookies.get('alertMessage') !== JSON.stringify(alertMessage))) {
      setIsShowModal(true);
    }
  }, [alertMessage]);

  const handleClose = () => {
    if (neverShowAgain) {
      cookies.set('alertMessage', JSON.stringify(alertMessage));
    }
    setIsShowModal(false);
  }

  return (
    <BaseModalNew
      isOpen={isShowModal}
      modalFoot={
        <PrimaryButton
          children={t('close')}
          onClick={handleClose}
        />
      }
      onClose={handleClose}
      title={alertMessage.title}
    >
      <div>
        <p>{alertMessage.content}</p>
        <div style={{ marginTop: 20 }}>
          <Checkbox
            checked={neverShowAgain}
            onChange={() => {
              setNeverShowAgain(prev => !prev);
            }}
          />
          {t('neverShowAgain')}
        </div>
      </div>
    </BaseModalNew>
  );
}

export default AnnouncementModal;